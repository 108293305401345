import React, { useMemo, useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import useWindowSize from "@Hooks/useWindowSize";
export default function CarouselLandingPageClass() {
  const { width } = useWindowSize();

  const carouselGallery = useMemo(() => {
    return [
      {
        image: Images.PARCEL_BOX,
        title: "Sales",
      },
      {
        image: Images.MAP_LANDING_PAGE,
        title: "Bahasa",
      },
      {
        image: Images.NOTES_LANDING_PAGE,
        title: "Jurnalistik",
      },
      {
        image: Images.PERCENTAGE_LANDING_PAGE,
        title: "Manajemen",
      },
      {
        image: Images.GLOBE_LANDING_PAGE,
        title: "Bisnis",
      },
      {
        image: Images.DESIGN_LANDING_PAGE,
        title: "Desain",
      },
      {
        image: Images.ART_LANDING_PAGE,
        title: width < 768 ? "Kerajinan" : "Seni/Kerajinan",
      },
      {
        image: Images.LIFE_STYLE_LANDING_PAGE,
        title: "Lifestyle",
      },
      {
        image: Images.MARKETING_LANDING_PAGE,
        title: "Marketing",
      },
      {
        image: Images.HEADPHONE_LANDING_PAGE,
        title: "Komunikasi",
      },
    ];
  }, [width]);
  const nextRef = useRef();
  const prevNext = useRef();

  const handleNext = () => {
    nextRef?.current?.nextSibling?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      top: "0",
    });
  };
  const handlePrev = () => {
    prevNext?.current?.nextSibling?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      top: "0",
    });
  };
  const isMobile = width < 768;
  return (
    <div className={Styles.container}>
      <div className={Styles.headerClassCategory} id="categories">
        <h1>
          Pilih Kelas <span>{" Skills.id "}</span> Berdasarkan Kategori
        </h1>
      </div>
      <div className={Styles.carouselCategory}>
        <div className={Styles.leftIcon} onClick={handlePrev}>
          <Icon icon={"arrow-left"} className={Styles.iconL} />
        </div>

        <div
          className={Styles.relativeButton}
          ismobile={isMobile ? "true" : "false"}
        >
          {carouselGallery.map((item, idx) => (
            <div className={Styles.cardCategory} key={idx}>
              <img
                src={item.image}
                alt="categories"
                ref={idx === 0 ? prevNext : nextRef}
                className={Styles.imageCarousel}
              />
              <div className={Styles.title}> {item.title} </div>
            </div>
          ))}
        </div>

        <div className={Styles.rightIcon} onClick={handleNext}>
          <Icon icon={"arrow-left"} className={Styles.iconR} />
        </div>
      </div>
    </div>
  );
}
